<template>
  <fieldset class="pa-4 mb-4 white--text text--darken-4">
    <legend class="px-1">
      <slot name="legend">Legend</slot>
    </legend>
    <slot />
  </fieldset>
</template>

<script>
export default {
  name: 'pibot-form-fieldset'
}
</script>
